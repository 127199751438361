<template>
  <ui-component-modal
    :modalTitle="$t('Components.Terms.ModalDeleteBookingTerm.Main_Title')"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed || isDeleting"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div
        class="has-margin-bottom is-size-5 has-text-centered"
        v-html="
          $t('Components.Terms.ModalDeleteBookingTerm.Message_Warning', {
            name: bookingTerm.Version,
          })
        "
      ></div>
      <div class="has-text-centered">
        <BaseCheckbox
          v-model="isDeleteConfirmed"
          class="has-text-left"
          :label="$t('Components.Hosts.ModalDeleteHost.Text_YesImSure')"
        />
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import termsProvider from '@/providers/terms'

const BaseCheckbox = () => import('@/components/UI/Form/BaseCheckbox')

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    bookingTerm: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    save() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        termsProvider.methods
          .deleteTerm(self.bookingTerm.Id)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true
              //console.log("response delete?", response.data)

              setTimeout(() => {
                self.$emit('termDeleted')
                this.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
